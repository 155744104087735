/* ====================================
// css variables
// ==================================== */

$color-black: #000000;
$color-white: #ffffff;
$color-enhanced: #ff0000;

@font-face {
  font-family: "General Sans";
  src: url(../fonts/GeneralSans-Variable.woff2);
}

@font-face {
  font-family: "icon";
  src: url(../fonts/fa-sharp-regular-400.woff2);
}

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  font-family: "Noi Grotesk", sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 1rem;
  line-height: 1.25;
}

a {
  color: inherit;
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

/* ====================================
// base rules
// ==================================== */

html.archive {
  body {
    width: 100vw;
    background-color: #dddee2;
    display: flex;
    flex-direction: column;
    .grain {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10000;
      background: url("../img/grain.png");
      background-repeat: repeat;
      background-size: 20%;
      mix-blend-mode: overlay;
      opacity: 0.8;
    }
    h1 {
      line-height: normal !important;
    }
    header {
      height: 10vh;
      .l-row {
        display: flex;
        padding: 10 100px;
        justify-content: space-between;
        align-items: center;
      }
    }
    footer {
      color: #89898c;
      padding: 10px 80px;
      p {
        font-size: 0.8rem;
      }
    }
    .icon {
      font-family: "icon";
      cursor: pointer;
    }
    main.app {
      height: 80vh;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      .art-perso,
      .art-data {
        width: 30%;
        top: 30%;
        position: absolute;
        z-index: 0;
        pointer-events: none;
      }
      .art-perso {
        right: 100px;
      }
      .art-data {
        left: 100px;
      }
      .art-scene {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        div {
          box-sizing: content-box;
          position: relative;
          height: 50%;
          z-index: 1;
          border-radius: 7px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          filter: drop-shadow(0 0 80px rgba(0, 0, 0, 0.211));
          &.pieta {
            background-image: url("../img/pieta.png");
            aspect-ratio: 1/1;
          }
          &.lacathedraledekoln {
            background-image: url("../img/koln-cathedral.jpg");
            aspect-ratio: 4/3;
          }
          &.lacathedralederouen {
            background-image: url("../img/lacathedralederouen.jpg");
            aspect-ratio: 733/1080;
            height: 80%;
          }
          &.nakagincapsuletower {
            background-image: url("../img/nakagin-tower.jpg");
            aspect-ratio: 5/4;
          }
          &.untitled {
            background-image: url("../img/untitled.jpg");
            aspect-ratio: 899/600;
          }
          &.mountains {
            background-image: url("../img/mountains.jpg");
            aspect-ratio: 9/16;
            height: 80%;
          }
          &.meltingmemories {
            background-image: url("../img/melting-memories.jpg");
            aspect-ratio: 3/2;
          }
          div.shine {
            width: 100%;
            height: 100%;
            position: absolute;
            backface-visibility: hidden;

            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0.342),
              rgba(255, 255, 255, 0.1)
            );
            filter: none;
          }
        }
      }
    }
  }

  .controllers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 10%;
    gap: 100px;
    padding: 0 100px;
    .art-trigger {
      z-index: 100;
      transition: transform 0.3s;

      &:hover {
        transform: scale(2);
        color: $color-enhanced;
      }
      &::selection {
        background-color: transparent;
        color: inherit;
      }
    }
  }
}
