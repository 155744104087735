@font-face {
  font-family: General Sans;
  src: url("../fonts/GeneralSans-Variable.woff2");
}

@font-face {
  font-family: icon;
  src: url("../fonts/fa-sharp-regular-400.woff2");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: Noi Grotesk, sans-serif;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  font-size: 1rem;
  line-height: 1.25;
}

a {
  color: inherit;
}

img, video {
  vertical-align: middle;
  block-size: auto;
  max-inline-size: 100%;
}

html.archive body {
  background-color: #dddee2;
  flex-direction: column;
  width: 100vw;
  display: flex;
}

html.archive body .grain {
  pointer-events: none;
  z-index: 10000;
  mix-blend-mode: overlay;
  opacity: .8;
  background: url("../img/grain.a670afd8.png") 0 0 / 20%;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

html.archive body h1 {
  line-height: normal !important;
}

html.archive body header {
  height: 10vh;
}

html.archive body header .l-row {
  justify-content: space-between;
  align-items: center;
  padding: 10px 100px;
  display: flex;
}

html.archive body footer {
  color: #89898c;
  padding: 10px 80px;
}

html.archive body footer p {
  font-size: .8rem;
}

html.archive body .icon {
  cursor: pointer;
  font-family: icon;
}

html.archive body main.app {
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  height: 80vh;
  display: flex;
  position: relative;
}

html.archive body main.app .art-perso, html.archive body main.app .art-data {
  z-index: 0;
  pointer-events: none;
  width: 30%;
  position: absolute;
  top: 30%;
}

html.archive body main.app .art-perso {
  right: 100px;
}

html.archive body main.app .art-data {
  left: 100px;
}

html.archive body main.app .art-scene {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

html.archive body main.app .art-scene div {
  box-sizing: content-box;
  z-index: 1;
  filter: drop-shadow(0 0 80px #00000036);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 7px;
  height: 50%;
  position: relative;
}

html.archive body main.app .art-scene div.pieta {
  aspect-ratio: 1;
  background-image: url("../img/pieta.17ff2de5.png");
}

html.archive body main.app .art-scene div.lacathedraledekoln {
  aspect-ratio: 4 / 3;
  background-image: url("../img/koln-cathedral.06c64c7b.jpg");
}

html.archive body main.app .art-scene div.lacathedralederouen {
  aspect-ratio: 733 / 1080;
  background-image: url("../img/lacathedralederouen.0f1ecd9a.jpg");
  height: 80%;
}

html.archive body main.app .art-scene div.nakagincapsuletower {
  aspect-ratio: 5 / 4;
  background-image: url("../img/nakagin-tower.8225c818.jpg");
}

html.archive body main.app .art-scene div.untitled {
  aspect-ratio: 899 / 600;
  background-image: url("../img/untitled.236f69f6.jpg");
}

html.archive body main.app .art-scene div.mountains {
  aspect-ratio: 9 / 16;
  background-image: url("../img/mountains.72fd9bf9.jpg");
  height: 80%;
}

html.archive body main.app .art-scene div.meltingmemories {
  aspect-ratio: 3 / 2;
  background-image: url("../img/melting-memories.b4e0bae2.jpg");
}

html.archive body main.app .art-scene div div.shine {
  backface-visibility: hidden;
  filter: none;
  background: linear-gradient(90deg, #ffffff57, #ffffff1a);
  width: 100%;
  height: 100%;
  position: absolute;
}

html.archive .controllers {
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  padding: 0 100px;
  display: flex;
  position: absolute;
  bottom: 10%;
}

html.archive .controllers .art-trigger {
  z-index: 100;
  transition: transform .3s;
}

html.archive .controllers .art-trigger:hover {
  color: red;
  transform: scale(2);
}

html.archive .controllers .art-trigger::selection {
  color: inherit;
  background-color: #0000;
}

/*# sourceMappingURL=index.b435469e.css.map */
